import React, { useState } from "react"
import { Button } from "../components/ButtonElement"
import CookieInformation from "../components/CookiePage"
import { GlobalStyles } from "../components/GlobalStyles"
import styled from "styled-components"
import CookieBanner from "../components/CookieBanner"
const Wrapper = styled.div`
  max-width: 700px;
  padding: 4rem 1.5rem;
  margin: auto;
`
const CookiePage = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false)
  const openCookieBanner = () => {
    document.cookie = `gatsby-gdpr-google-analytics=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    setShowCookieBanner(true)
  }
  return (
    <>
      <GlobalStyles />
      {showCookieBanner ? <CookieBanner /> : null}
      <Wrapper>
        <CookieInformation />
        <Button
          isActive
          style={{ width: "auto", padding: "0 2em", textAlign: "center" }}
          onClick={() => openCookieBanner()}
        >
          Hantera cookieinställningar
        </Button>
      </Wrapper>
    </>
  )
}
export default CookiePage

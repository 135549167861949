import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  Heading,
  Description,
  FullName as Subtitle,
} from "../TeamSection/TeamElements"
import ArrowSvg from "./ArrowSvg"
const Wrapper = styled.article``
const Section = styled.section`
  margin: 2em 0;
`
const Address = styled.address`
  font-style: normal;
`
const AnchorLink = styled.a`
  color: var(--cta-color);
`
const CookieInformation = () => {
  return (
    <>
      <Wrapper>
        <Link to="/">
          <ArrowSvg />
        </Link>
        <Heading>Behandling av personuppgifter</Heading>
        <Description>
          Vi lagrar och hanterar dina personuppgifter enligt
          Dataskyddsförordningen, GDPR. Personuppgifter är information som,
          direkt eller indirekt, kan kopplas till dig som individ, som namn
          eller kontaktuppgifter.
        </Description>
        <Section>
          <Subtitle>Personuppgifter som vi behandlar om dig</Subtitle>
          <Description>
            I din kontakt med oss kan du komma att lämna vissa personuppgifter
            till oss, och vi behandlar då personuppgifterna du lämnat. Vi
            behandlar aldrig dina personuppgifter under en längre tid än vad som
            är tillåtet enligt tillämplig lag, förordning, praxis eller
            myndighetsbeslut. Om du vill veta vilka uppgifter vi har om dig,
            eller ändra eller radera dina uppgifter så kan du maila
            personuppgifter@sprinto.se.
          </Description>
        </Section>
        <Section>
          <Subtitle>Om cookies</Subtitle>
          <Description>
            {
              "När du använder vår webbplats kan det hända att personuppgifter samlas in via cookies. Detta görs dock endast via ett separat samtycke. Då lagras informationen om din användning och vilka sidor som besöks. Det kan vara teknisk information om din enhet och internetuppkoppling såsom operativsystem, webbläsarversion, IP-adress, cookies och unika identifierare. Läs mer om hur cookies används på "
            }
            <AnchorLink
              href="https://www.pts.se/sv/privat/internet/integritet/kakor-cookies/"
              target="_blank"
            >
              Post- och telestyrelsens hemsida.
            </AnchorLink>
          </Description>
        </Section>
        <Section>
          <Subtitle>Integritetsskyddsmyndigheten</Subtitle>
          <Description>
            {
              "Integritetsskyddsmyndigheten tillhandahåller mer information om GDPR och dina rättigheter till insyn och kontroll över hur dina personuppgifter behandlas. Här kan du även lämna klagomål om du tycker att dina uppgifter inte har behandlats på rätt sätt. Läs mer om GDPR och dina rättigheter på "
            }
            <AnchorLink
              href="https://www.imy.se/verksamhet/dataskydd/"
              target="_blank"
            >
              Integritetsskyddsmyndighetens hemsida.
            </AnchorLink>
          </Description>
        </Section>
        <Address>
          <Subtitle>Personuppgiftsansvarig</Subtitle>
          <Description>
            Sprinto Sweden AB <br /> Marmorgatan 13A <br />
            118 67 Stockholm <br />{" "}
            <AnchorLink href="mailto:personuppgifter@sprinto.se">
              personuppgifter@sprinto.se
            </AnchorLink>
          </Description>
        </Address>
      </Wrapper>
    </>
  )
}
export default CookieInformation
